<template>
  <div class="product-box">
    <!-- banner部分 -->
    <div class="product-banner">
      <div class="product-bannerbox">
        <div class="product-leftbox">
          <div class="product-imgbox">
            <img
              src="https://qncdn.uni-sheep.com/s/Group%201033.png"
              alt=""
            />
          </div>
        </div>
        <div class="product-rightbox">
          <div class="product-conbox">
            <div class="product-sheepimg">
              <img
                src="https://qncdn.uni-sheep.com/s/UniSheep%20%E7%BE%8A%E5%8F%AA%E7%94%9F%E4%BA%A7%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%281%29.png"
                alt=""
              />
            </div>
            <div class="product-text family-text">
              打造牧场流程化管理平台，提高牧场 生产力
            </div>
            <div class="product-shiyong" @click="Immediate">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20677%281%29.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 标准的流程化服务体系 -->
    <div class="standardbox">
      <div class="stancenterbox">
        <div class="stanc-topbox">
          <div class="sta-title family-title">标准的流程化服务体系</div>
          <div class="sta-miaos family-text">为牧场提高执行力</div>
        </div>
        <div class="stanc-bottom" v-if="standardisplay">
          <img
            src="https://qncdn.uni-sheep.com/s/Group%201032.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- tab流程 -->

    <div class="flowpath-box" v-if="tabdisplay">
      <div class="flow-tit">
        <div class="flow-title family-title">一目了然的流程化管理</div>
        <div class="flow-miaos family-text">提升牧场执行力</div>
      </div>
      <div class="flowpath-tabox">
        <div class="flowpath-leftbox">
          <div
            class="flow-mei"
            v-for="(item, index) in list"
            :key="index"
            @click="changes(index)"
            @mouseover="mouserOve(index)"
            :class="{ active: index == num }"
          >
            <div class="flow-img">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="flow-biao">{{ item.title }}</div>
          </div>
        </div>
        <!-- 属性控制盒子 -->
        <div class="flowpath-rightbox" v-show="num == 0">
          <img
            src="https://qncdn.uni-sheep.com/s/lQLPJxHG9bYakWjNBjDNCySwAHvW2xMMeG8E0jkZ-4DWAA_2852_1584.png"
            alt=""
          />
        </div>
        <div class="flowpath-rightbox" v-show="num == 1">
          <img
            src="https://qncdn.uni-sheep.com/s/Group%20936.png"
            alt=""
          /></div>
        <div class="flowpath-rightbox" v-show="num == 2">
          <img
            src="https://qncdn.uni-sheep.com/s/Frame%20967.png"
            alt=""
          /></div>
        <div class="flowpath-rightbox" v-show="num == 3">  <img
            src="https://qncdn.uni-sheep.com/s/Frame%20966.png"
            alt=""
          /></div>
     
      </div>
    </div>
   
    <!-- 生产经营报表-->
    <div class="produce-box">
      <div class="produce-center" v-if="producedisplay">
        <div class="produce-leftbox">
          <div class="prozuo" @click="prevImage">
            <img src="https://qncdn.uni-sheep.com/s/Group%20625%281%29.png" alt="" />
          </div>
          <div class="pro-photos">
            <transition name="fade" mode="out-in">
              <img
                :src="currentImage"
                :key="currentImage"
                alt="Slideshow Image"
              />
            </transition>
          </div>
          <div class="prozuo" @click="nextImage">
            <img src="https://qncdn.uni-sheep.com/s/Group%20626%281%29.png" alt="" />
          </div>
        </div>
        <div class="produce-rightbox">
          <div class="produce-titop">
            <div class="pro-tis family-title">生产经营报表</div>
            <div class="pro-mi family-text">报表一目了然</div>
          </div>
          <div class="produce-tibop">
            <div class="pro-fir">
              <div class="pro-xiao">
                <img
                  src="https://qncdn.uni-sheep.com/s/Frame%20983.png"
                  alt=""
                />
              </div>
              <div class="pro-nei family-text">从数据中心发现问题，用数据指导牧场生产</div>
            </div>
            <div class="pro-fir">
              <div class="pro-xiao">
                <img
                  src="https://qncdn.uni-sheep.com/s/Frame%20983.png"
                  alt=""
                />
              </div>
              <div class="pro-nei family-text">
                自定义分析（让生产人员摆脱工厂技术的束缚）
              </div>
            </div>
            <div class="pro-fir">
              <div class="pro-xiao">
                <img
                  src="https://qncdn.uni-sheep.com/s/Frame%20983.png"
                  alt=""
                />
              </div>
              <div class="pro-nei family-text">多样化分析报表更简单、更高效、更专业</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kongbai"></div>

    <!-- 公共脚步部分 -->
    <public-footer @Immediately="Immediately"></public-footer>
  </div>
</template>
<script>
import PublicFooter from "../../../components/publicfooter/index.vue";

export default {
  metaInfo: {
    title: 'UniSheep羊只生产管理平台-自牧天成科技发展有限公司',
    // meta: [
    //   {
    //     name: 'description',
    //     content: 'This is my page description'
    //   }
    // ]
  },
  components: {
    PublicFooter,
  },
  props: [],
  data() {
    return {
      list: [
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20798%281%29.png",
          title: "流程管理",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20800%281%29.png",
          title: "事件触发",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20801%281%29.png",
          title: "派工单",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Group%20802%20%281%29%281%29.png",
          title: "生产经营报表",
        },
      ],
      num: 0,
      // 滚动高度
      scrollTop: "",
      //  标准的流程化服务体系动画
      standardisplay: false,
      // 一目了然的流程化管理动画
      tabdisplay: false,
      //  生产经营报表
      producedisplay: false,
      // 当前显示图片的索引
      currentIndex: 0,
      // 生产经营报表
      images: [
        "https://qncdn.uni-sheep.com/s/30%E5%A4%A9%E6%96%B0%E4%BA%A7%E7%96%BE%E7%97%85.png",
        "https://qncdn.uni-sheep.com/s/%E5%A4%9A%E7%BB%B4%E5%BA%A6.png",
        "https://qncdn.uni-sheep.com/s/%E7%B9%81%E6%AE%96%E7%8A%B6%E5%86%B5%E6%8A%A5%E8%A1%A8.png",
        "https://qncdn.uni-sheep.com/s/%E5%90%8E%E5%A4%87%E7%BE%8A%E5%8F%91%E7%97%85.png",
        "https://qncdn.uni-sheep.com/s/%E6%B5%81%E4%BA%A7%E7%8E%87%E5%88%86%E6%9E%90.png",
        "https://qncdn.uni-sheep.com/s/%E9%85%8D%E7%A7%8D%E9%97%B4%E9%9A%94%E6%95%88%E7%8E%87.png",
      ],
    };
  },
  methods: {
    changes(key) {
      this.num = key;
    },
    mouserOve(key) {
      this.num = key;
    },
    // 立即咨询
    Immediately() {
      this.$router.push("/fillinformation");
    },
    //立即试用
    Immediate(){
      this.$router.push("/fillinformation");
    },
    // 监听浏览器
    listenerFunction() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // 获取浏览器滚动条高度
    handleScroll(event) {
      // console.log("滚动条高度为：", event.target.scrollTop);
      this.scrollTop = event.target.scrollTop;
      const topObj = {
        100: "standardisplay",
        800: "tabdisplay",
        1800: "producedisplay",
      };
      for (const top in topObj) {
        this.scrollTop >= top && (this[topObj[top]] = true);
      }
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.images.length - 1;
      }
    },
  },

  created() {
    this.listenerFunction();
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },
  beforeDistroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
};
</script>
<style scoped lang="scss">
// 公共动画
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
// 属性控制盒子内容动画
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

.product-box {
  width: 100%;
  height: 100%;
  //banner部分
  .product-banner {
    width: 100%;
    height: 38rem;
    background-image: linear-gradient(to bottom, #ecf4fe, #ffffff);
    .product-bannerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      .product-leftbox {
        width: 45.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        .product-imgbox {
          width: 45.5rem;
          height: 34.39rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .product-rightbox {
        width: 35.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .product-conbox {
          width: 18rem;
          height: 22rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .product-sheepimg {
            width: 18rem;
            height: 5.5rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .product-text {
            color: #767a7e;
            font-size: 1.1rem;
            line-height: 1.8rem;
          }
          .product-shiyong {
            width: 11.6875rem;
            height: 3.5rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .product-shiyong:hover {
            cursor: pointer;
            width: 12.6875rem;
            height: 3.8rem;
          }
        }
      }
    }
  }
  // 标准的流程化服务体系
  .standardbox {
    width: 100%;
    height: 52rem;
    .stancenterbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .stanc-topbox {
        width: 22.8125rem;
        height: 4.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 3.5rem;
        .sta-title {
          font-size: 2rem;
          font-weight: 800;
        }
        .sta-miaos {
          color: #353a67;
          font-size: 1.3rem;
        }
      }
      .stanc-bottom {
        margin-top: 2rem;
        width: 100%;
        height: 38.25rem;
        animation: slide-up 1.5s ease-in-out;
        animation-fill-mode: forwards;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  //tab流程
  .flowpath-box {
    width: 100%;
    height: 42rem;
    animation: slide-up 1.5s ease-in-out;
    animation-fill-mode: forwards;
    .flow-tit {
      width: 22.8125rem;
      height: 4.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      margin-top: 4rem;
      .flow-title {
        font-size: 2rem;
        font-weight: 800;
      }
      .flow-miaos {
        color: #353a67;
        font-size: 1.3rem;
      }
    }
    .flowpath-tabox {
      width: 72.5rem;
      height: 31.6875rem;
      margin: 0 auto;
      background: url("https://qncdn.uni-sheep.com/s/Group%20988%281%29.png")
        center center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      margin-top: 3rem;
      .flowpath-leftbox {
        width: 15.375rem;
        height: 23.75rem;
        .flow-mei {
          width: 100%;
          height: 5.9375rem;
          display: flex;
          align-items: center;
          .flow-img {
            width: 1.95rem;
            height: 1.87rem;
            border-radius: 0.375rem;
            margin-left: 2rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .flow-biao {
            font-size: 1.4rem;
            margin-left: 1rem;
          }
        }
        .active {
          font-weight: 800;
          border-radius: 0.3125rem;
          height: 5.9375rem;
          background: url("https://qncdn.uni-sheep.com/s/Union%20%282%29%281%29.png")
          ;
          background-repeat: no-repeat;
          background-size: cover;
          .flow-img {
            background: rgb(20, 92, 254);
            animation: iconTrans 0.2s ease-in-out;
            box-shadow: 0.125rem 0.125rem 0.5rem #145cfe45;
            img {
              filter: brightness(88);
            }
          }
          @keyframes iconTrans {
            0% {
              transform: scale(0.7);
            }
            100% {
              transform: scale(1);
            }
          }
        }
      }
      .flowpath-rightbox {
        width: 46.5625rem;
        height: 23.75rem;
     
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  //生产经营报表
  .produce-box {
    width: 100%;
    height: 46.7175rem;
    background: url("https://qncdn.uni-sheep.com/s/Group%20814%281%29.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .produce-center {
      width: 85.25rem;
      height: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      margin-top: 2.5rem;
      .produce-leftbox {
        width: 62%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .prozuo {
          width: 2.625rem;
          height: 2.625rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .pro-photos {
          width: 45rem;
          height: 100%;
       
          .fade-enter-active,
          .fade-leave-active {
            transition: transform 0.2s;
          }

          .fade-enter,
          .fade-leave-to {
            transform: translateX(20%);
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .produce-rightbox {
        width: 33%;
        height: 100%;
        .produce-titop {
          width: 14.0625rem;
          height: 4.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .pro-tis {
            font-size: 2rem;
            font-weight: 800;
          }
          .pro-mi {
            font-size: 1.3rem;
            color: #353a67;
          }
        }
        .produce-tibop {
          width: 100%;
          height: 15rem;
          margin-top: 3rem;
          .pro-fir {
            width: 100%;
            height: 5rem;
            display: flex;
            align-items: center;
            .pro-xiao {
              width: 1.5rem;
              height: 1.5rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .pro-nei {
              font-size: 1rem;
              font-weight: 800;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
  .kongbai {
    width: 100%;
    height: 2rem;
  }
}
</style>
